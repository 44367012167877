import React, { useState } from "react";
import { MdCloudUpload, MdDelete, MdAdd } from "react-icons/md";
import { FaYoutube } from "react-icons/fa";
import imageCompression from "browser-image-compression";
import swal from "sweetalert";
import auth from "../../firebase.init";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import TextEditor from "../../Components/Shared/TextEditor";
import placeholder from "../../assets/images/placeholder-image.jpg";
const AddProduct = () => {
  const [user, loading] = useAuthState(auth);
  const [loadingData, setLoadingData] = useState(false);
  const navigate = useNavigate();
  const email = user?.email;

  // Form Data State
  const [formData, setFormData] = useState({
    category: "",
    productName: "",
    description: "",
    price: "",
    quantity: "",
    discount: "",
    status: "",
    youtubeLink: "",
    refundPolicy: "",
    addedBy: email,
  });

  // Images and Colors State
  const [images, setImages] = useState([null, null, null, null]);
  const [colors, setColors] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [addLoading, setAddLoading] = useState(false);
  // Handle Form Input Changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle Image Upload
  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const newImages = [...images];
      newImages[index] = {
        file,
        preview: URL.createObjectURL(file),
      };
      setImages(newImages);
    }
  };

  // Remove Image
  const removeImage = (index) => {
    const newImages = [...images];
    if (newImages[index]?.preview) {
      URL.revokeObjectURL(newImages[index].preview);
    }
    newImages[index] = null;
    setImages(newImages);
  };

  // Add New Color
  const addNewColor = () => {
    setColors([...colors, { color: "#000000", image: null }]);
  };
  const addNewReview = () => {
    setReviews([...reviews, { review: "", image: null }]);
  };

  // Remove Color
  const removeColor = (index) => {
    const newColors = colors.filter((_, i) => i !== index);
    setColors(newColors);
  };
  const removeReview = (index) => {
    const newReviews = reviews.filter((_, i) => i !== index);
    setReviews(newReviews);
  };

  // Handle Color Change
  const handleColorChange = (index, value) => {
    const newColors = [...colors];
    newColors[index] = {
      ...newColors[index],
      color: value,
    };
    setColors(newColors);
  };
  const handleReviewChange = (index, value) => {
    const newReviews = [...reviews];
    newReviews[index] = {
      ...newReviews[index],
      review: value,
    };
    setReviews(newReviews);
  };

  // Handle Color Image Change
  const handleColorImageChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const newColors = [...colors];
      newColors[index] = {
        ...newColors[index],
        image: {
          file,
          preview: URL.createObjectURL(file),
        },
      };
      setColors(newColors);
    }
  };
  const handleReviewImageChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const newReviews = [...reviews];
      newReviews[index] = {
        ...newReviews[index],
        image: {
          file,
          preview: URL.createObjectURL(file),
        },
      };
      setReviews(newReviews);
    }
  };

  // Handle Form Submit

  const uploadImages = async (colors, productImages, reviews) => {
    try {
      // Upload color images
      const uploadedColors = await Promise.all(
        colors.map(async (colorObj) => {
          if (colorObj.image && colorObj.image.file) {
            try {
              // Compression options
              const options = {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 800,
                useWebWorker: true,
              };

              // Compress image
              const compressedImage = await imageCompression(
                colorObj.image.file,
                options
              );

              const formData = new FormData();
              formData.append("image", compressedImage);
              formData.append("extension", colorObj.image.extension);

              const res = await fetch(
                `${process.env.REACT_APP_API_URL}/upload`,
                {
                  method: "POST",
                  body: formData,
                }
              );

              const result = await res.json();

              // Validate response before returning
              if (!result.data?.url || typeof result.data.url !== "string") {
                throw new Error("Image upload failed");
              }

              return { color: colorObj.color, image: result.data.url }; // Ensure `image` is a string
            } catch (error) {
              console.error("Color image compression error:", error);
              return null; // Return null for failed uploads
            }
          }
          return null; // Ignore invalid color objects
        })
      );

      // Remove null values
      const validColors = uploadedColors.filter((color) => color !== null);

      // Upload review images
      const uploadedReviews = await Promise.all(
        reviews.map(async (reviewObj) => {
          if (reviewObj.image && reviewObj.image.file) {
            try {
              // Compression options
              const options = {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 800,
                useWebWorker: true,
              };

              // Compress image
              const compressedImage = await imageCompression(
                reviewObj.image.file,
                options
              );

              const formData = new FormData();
              formData.append("image", compressedImage);
              formData.append("extension", reviewObj.image.extension);

              const res = await fetch(
                `${process.env.REACT_APP_API_URL}/upload`,
                {
                  method: "POST",
                  body: formData,
                }
              );

              const result = await res.json();

              // Validate response before returning
              if (!result.data?.url || typeof result.data.url !== "string") {
                throw new Error("Review image upload failed");
              }

              return { review: reviewObj.review, image: result.data.url }; // Ensure `image` is a string
            } catch (error) {
              console.error("Review image compression error:", error);
              return null;
            }
          }
          return null;
        })
      );

      // Remove null values
      const validReviews = uploadedReviews.filter((review) => review !== null);

      // Upload product images
      const uploadedProductImages = await Promise.all(
        productImages
          .filter((img) => img !== null && img.file) // Ensure valid images
          .map(async (imageObj) => {
            try {
              // Compression options
              const options = {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 800,
                useWebWorker: true,
              };

              // Compress image
              const compressedImage = await imageCompression(
                imageObj.file,
                options
              );

              const formData = new FormData();
              formData.append("image", compressedImage);
              formData.append("extension", imageObj.extension);

              const res = await fetch(
                `${process.env.REACT_APP_API_URL}/upload`,
                {
                  method: "POST",
                  body: formData,
                }
              );

              const result = await res.json();

              // Validate response before returning
              if (!result.data?.url || typeof result.data.url !== "string") {
                throw new Error("Product image upload failed");
              }

              return result.data.url; // Ensure `image` is a string
            } catch (error) {
              console.error("Product image compression error:", error);
              return null;
            }
          })
      );

      // Remove null values
      const validProductImages = uploadedProductImages.filter(
        (img) => img !== null
      );

      return {
        colors: validColors,
        productImages: validProductImages,
        reviews: validReviews,
      };
    } catch (error) {
      console.error("Upload error:", error);
      throw new Error("Failed to upload images");
    }
  };

  // Usage in handleSubmit
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setAddLoading(true);

  //   try {
  //     // Upload all images first
  //     const uploadedImages = await uploadImages(colors, images);

  //     // Create final form data with uploaded image URLs
  //     const finalData = {
  //       ...formData,
  //       colors: uploadedImages.colors,
  //       productImages: uploadedImages.productImages,
  //       reviews: uploadedImages.reviews,
  //     };

  //     // Send the final data to your API
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}/product`, {
  //       method: "POST",
  //       headers: {
  //         authorization: `Bearer ${user?.accessToken}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(finalData),
  //     });
  //     const responseData = await response.json();

  //     if (!response.ok) {
  //       throw new Error(responseData?.error);
  //     }

  //     // Reset form after successful submission
  //     setFormData({
  //       category: "",
  //       productName: "",
  //       description: "",
  //       price: "",
  //       quantity: "",
  //       discount: "",
  //       status: "",
  //       youtubeLink: "",
  //       additionalInfo: "",
  //       refundPolicy: "",
  //       addedBy: "",
  //     });
  //     setAddLoading(false);
  //     setImages([null, null, null, null]);
  //     setColors([]);
  //     setReviews([]);

  //     swal("Success", "Product Added Successfully", "success");
  //   } catch (error) {
  //     setAddLoading(false);
  //     console.error("Error submitting form:", error);
  //     swal("Error", `${error}`, "error");
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAddLoading(true);

    // Validation
    if (
      !formData.category ||
      !formData.productName ||
      !formData.description ||
      !formData.refundPolicy ||
      !formData.price ||
      !formData.quantity ||
      !formData.status ||
      !formData.addedBy
    ) {
      swal("Warning", "All fields are required.", "warning");
      setAddLoading(false);
      return;
    }

    if (isNaN(formData.price) || Number(formData.price) < 0) {
      swal("Warning", "Price must be a valid non-negative number.", "warning");
      setAddLoading(false);
      return;
    }

    if (isNaN(formData.quantity) || Number(formData.quantity) < 0) {
      swal(
        "Warning",
        "Quantity must be a valid non-negative number.",
        "warning"
      );
      setAddLoading(false);
      return;
    }

    if (
      formData.discount &&
      (isNaN(formData.discount) || Number(formData.discount) < 0)
    ) {
      swal(
        "Warning",
        "Discount must be a valid non-negative number.",
        "warning"
      );
      setAddLoading(false);
      return;
    }

    if (formData.youtubeLink == "") {
      swal("Warning", "Youtube link is required.", "warning");
      setAddLoading(false);
      return;
    }

    if (
      formData.addedBy &&
      !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.addedBy)
    ) {
      swal("Warning", "Invalid email address.", "warning");
      setAddLoading(false);
      return;
    }

    // Validate images

    if (!Array.isArray(images) || !images.some((img) => img !== null)) {
      swal("Warning", "At least one product image must be added.", "warning");
      setAddLoading(false);
      return;
    }

    // Validate colors (optional but recommended)
    if (!Array.isArray(colors) || colors.length === 0) {
      swal(
        "Warning",
        "At least one color must be added for the product.",
        "warning"
      );
      setAddLoading(false);
      return;
    }

    const invalidColor = colors.find((color) => !color.image);
    if (invalidColor) {
      swal("Warning", "Each color must have an image uploaded.", "warning");
      setAddLoading(false);
      return;
    }
    // Validate reviews (if required)

    try {
      // Upload all images first
      const uploadedImages = await uploadImages(colors, images, reviews);
      if (
        !uploadedImages ||
        !uploadedImages.productImages.length ||
        uploadedImages.productImages.some((img) => !img)
      ) {
        swal("Error", "Image upload failed. Please try again.", "error");
        setAddLoading(false);
        return;
      }
      // Create final form data with uploaded image URLs
      const finalData = {
        ...formData,
        colors: uploadedImages.colors,
        productImages: uploadedImages.productImages,
        reviews: uploadedImages.reviews,
      };

      // Send the final data to your API
      const response = await fetch(`${process.env.REACT_APP_API_URL}/product`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${user?.accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(finalData),
      });

      const responseData = await response.json();
      if (!response.ok) {
        return swal("Error", responseData?.error, "error");
      }

      // Reset form after successful submission
      setFormData({
        category: "",
        productName: "",
        description: "",
        price: "",
        quantity: "",
        discount: "",
        status: "",
        youtubeLink: "",
        additionalInfo: "",
        refundPolicy: "",
        addedBy: "",
      });

      // Reset images, colors, and reviews
      setImages([null, null, null, null]);
      setColors([]);
      setReviews([]);

      setAddLoading(false);

      // Show success toast
      swal("Success", "Product Added Successfully", "success");
      navigate("/dashboard/manage-products");
    } catch (error) {
      setAddLoading(false);
      console.error("Error submitting form:", error);

      // Show error toast
      swal("Error", `${error}`, "error");
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4">
      <div className="max-w-2xl mx-auto">
        <h1 className="text-2xl font-bold text-center mb-8">Add Product</h1>

        <form
          onSubmit={handleSubmit}
          className="space-y-6 bg-white p-6 rounded-lg shadow"
        >
          {/* Category Select */}
          <select
            name="category"
            value={formData.category}
            onChange={handleInputChange}
            className="w-full p-2.5 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Select Category</option>
            <option value="borka">Borka</option>
            <option value="hijab">Hijab</option>
            <option value="abaya">Abaya</option>
          </select>

          {/* Product Name */}
          <input
            type="text"
            name="productName"
            value={formData.productName}
            onChange={handleInputChange}
            placeholder="Product Name"
            className="w-full p-2.5 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />

          {/* Product Description */}
          {/* <textarea
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            placeholder="Product Description"
            rows="3"
            className="w-full p-2.5 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          /> */}
          <TextEditor
            value={formData.description}
            onChange={(text) => setFormData({ ...formData, description: text })}
            placeholder="Description"
          />

          {/* <TextEditor
            value={formData.additionalInfo}
            onChange={(text) =>
              setFormData({ ...formData, additionalInfo: text })
            }
            placeholder="Additional Information"
          /> */}
          <TextEditor
            value={formData.refundPolicy}
            onChange={(text) =>
              setFormData({ ...formData, refundPolicy: text })
            }
            placeholder="Refund Policy"
          />

          {/* Price per unit */}
          <input
            type="number"
            name="price"
            value={formData.price}
            onChange={handleInputChange}
            placeholder="Product Price per unit"
            className="w-full p-2.5 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />

          {/* Available Quantity */}
          <input
            type="number"
            name="quantity"
            value={formData.quantity}
            onChange={handleInputChange}
            placeholder="Available Quantity"
            className="w-full p-2.5 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />

          {/* Discount */}
          <input
            type="number"
            name="discount"
            value={formData.discount}
            onChange={handleInputChange}
            placeholder="Discount"
            className="w-full p-2.5 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />

          {/* Product Status */}
          <select
            name="status"
            value={formData.status}
            onChange={handleInputChange}
            className="w-full p-2.5 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Product Status</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>

          {/* Color-wise Images */}
          <div className="space-y-4">
            {colors.map((color, index) => (
              <div
                key={index}
                className="flex items-center gap-4 p-4 border rounded"
              >
                <div className="flex items-center gap-2 flex-1">
                  <input
                    type="color"
                    value={color.color}
                    onChange={(e) => handleColorChange(index, e.target.value)}
                    className="h-10 w-16 cursor-pointer rounded border border-gray-300"
                  />
                  <span className="text-sm text-gray-600 uppercase">
                    {color.color}
                  </span>
                </div>
                <div className="relative">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleColorImageChange(e, index)}
                    className="hidden"
                    id={`color-image-${index}`}
                  />
                  <label
                    htmlFor={`color-image-${index}`}
                    className="block w-20 h-20 border-2 border-dashed border-gray-300 rounded cursor-pointer"
                  >
                    {color.image?.preview ? (
                      <img
                        src={color.image.preview || placeholder}
                        alt="Color"
                        className="w-full h-full object-cover rounded"
                      />
                    ) : (
                      <div className="flex items-center justify-center h-full">
                        <MdCloudUpload className="text-2xl text-gray-400" />
                      </div>
                    )}
                  </label>
                </div>
                <button
                  type="button"
                  onClick={() => removeColor(index)}
                  className="p-2 text-red-500 hover:text-red-700"
                >
                  <MdDelete className="text-xl" />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={addNewColor}
              className="flex items-center gap-2 text-blue-500 hover:text-blue-700"
            >
              <MdAdd className="text-xl" />
              Add New Color
            </button>
          </div>

          <div className="space-y-4">
            {reviews.map((review, index) => (
              <div
                key={index}
                className="flex items-center gap-4 p-4 border rounded"
              >
                <div className="flex items-center gap-2 flex-1">
                  <textarea
                    value={review.review}
                    onChange={(e) => handleReviewChange(index, e.target.value)}
                    className="h-full w-full rounded border border-gray-300"
                  />
                  {/* <span className="text-sm text-gray-600 uppercase">
                    {review.review}
                  </span> */}
                </div>
                <div className="relative">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleReviewImageChange(e, index)}
                    className="hidden"
                    id={`review-image-${index}`}
                  />
                  <label
                    htmlFor={`review-image-${index}`}
                    className="block w-20 h-20 border-2 border-dashed border-gray-300 rounded cursor-pointer"
                  >
                    {review.image?.preview ? (
                      <img
                        src={review.image.preview || placeholder}
                        alt="Color"
                        className="w-full h-full object-cover rounded"
                      />
                    ) : (
                      <div className="flex items-center justify-center h-full">
                        <MdCloudUpload className="text-2xl text-gray-400" />
                      </div>
                    )}
                  </label>
                </div>
                <button
                  type="button"
                  onClick={() => removeReview(index)}
                  className="p-2 text-red-500 hover:text-red-700"
                >
                  <MdDelete className="text-xl" />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={addNewReview}
              className="flex items-center gap-2 text-blue-500 hover:text-blue-700"
            >
              <MdAdd className="text-xl" />
              Add New Review
            </button>
          </div>

          {/* YouTube Link */}
          <div className="relative">
            <FaYoutube className="absolute left-3 top-1/2 transform -translate-y-1/2 text-red-600 text-xl" />
            <input
              type="text"
              name="youtubeLink"
              value={formData.youtubeLink}
              onChange={handleInputChange}
              placeholder="YouTube Link"
              className="w-full p-2.5 pl-10 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          {/* Multiple Images Upload */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {images.map((image, index) => (
              <div key={index} className="relative">
                <div className="border-2 border-dashed border-gray-300 rounded p-2 h-24">
                  {image?.preview ? (
                    <div className="relative w-full h-full">
                      <img
                        src={image.preview || placeholder}
                        alt={`Product ${index + 1}`}
                        className="w-full h-full object-cover rounded"
                      />
                      <button
                        type="button"
                        onClick={() => removeImage(index)}
                        className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1"
                      >
                        <MdDelete className="text-sm" />
                      </button>
                    </div>
                  ) : (
                    <label className="cursor-pointer flex flex-col items-center justify-center h-full">
                      <MdCloudUpload className="text-2xl text-gray-400" />
                      <span className="text-xs text-gray-500">Upload</span>
                      <input
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={(e) => handleImageChange(e, index)}
                      />
                    </label>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={addLoading}
            className={`
              w-full mt-6 py-3 px-4 rounded-lg
              text-white font-medium
              transition-all duration-200
              flex items-center justify-center gap-2
              ${
                addLoading
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-emerald-500 hover:bg-emerald-600"
              }
            `}
          >
            {addLoading ? (
              <>
                <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                <span>Adding...</span>
              </>
            ) : (
              " Add Product"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddProduct;
