import React, { useState } from "react";

import { useAuthState } from "react-firebase-hooks/auth";
import { FaCartPlus } from "react-icons/fa";
import swal from "sweetalert";
import { Link, useNavigate } from "react-router-dom";
import auth from "../../firebase.init";
import { format } from "date-fns";
import { TailSpin } from "react-loader-spinner";
import {
  FiHeart,
  FiShoppingBag,
  FiFilter,
  FiChevronDown,
} from "react-icons/fi";

export default function ProductsCard(product) {
  const {
    _id,
    name,
    image,
    price,
    description,
    discount,
    colors,
    productName,
  } = product.product;
  const [user, loading] = useAuthState(auth);
  const [loadingData, setLoadingData] = useState(false);
  const navigate = useNavigate();

  const date = new Date();
  const formattedDate = format(date, "PP");
  const formattedDate2 = format(date, "p");

  return (
    <div className="group">
      <div className="relative bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-xl transition-all duration-300">
        {/* Product Image */}
        <div className="relative aspect-[3/4]">
          <img
            src={colors[0]?.image}
            alt="Product"
            className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
          />
          {/* New Tag */}
          <div className="absolute top-2 left-2">
            <span className="bg-black text-white text-xs px-2 py-1 rounded">
              NEW
            </span>
          </div>
          {/* Quick Actions */}
          {/* <div className="absolute top-2 right-2 flex flex-col gap-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            <button className="p-2 bg-white rounded-full shadow-md hover:bg-gray-100 transition-colors">
              <FiHeart className="w-5 h-5 text-gray-600" />
            </button>
            <button className="p-2 bg-white rounded-full shadow-md hover:bg-gray-100 transition-colors">
              <FiShoppingBag className="w-5 h-5 text-gray-600" />
            </button>
          </div> */}
          {/* Quick View Button */}
          <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-green-300 to-transparent translate-y-full group-hover:translate-y-0 transition-transform duration-300">
            <button
              onClick={() => navigate(`/productDetails/${_id}`)}
              className="w-full py-2 bg-white text-gray-900 rounded-lg font-medium hover:bg-gray-100 transition-colors"
            >
              Quick View
            </button>
          </div>
        </div>

        {/* Product Info */}
        <div className="p-4">
          <h3 className="font-medium text-gray-900 mb-1">{productName}</h3>
          <div className="flex items-center gap-2 mb-2">
            <span className="text-lg font-bold">
              {" "}
              ৳{parseFloat(price * (1 - discount / 100)).toFixed(0)}
            </span>
            {discount > 0 && (
              <>
                <span className="text-sm text-gray-500 line-through">
                  {" "}
                  ৳{price}
                </span>
                <span className="text-xs text-green-600 font-medium">
                  {discount}% OFF
                </span>
              </>
            )}
          </div>

          {/* Color Options */}
          <div className="flex items-center gap-1 mb-2">
            {colors.map((color, i) => (
              <button
                key={i}
                className="w-4 h-4 rounded-full border-2 border-white ring-2 ring-transparent hover:ring-gray-300 transition-all"
                style={{ backgroundColor: color.color }}
              />
            ))}
          </div>

          {/* Size Options */}
          {/* <div className="flex items-center gap-1">
            {["S", "M", "L", "XL"].map((size, i) => (
              <button
                key={i}
                className="min-w-[32px] h-8 px-2 text-sm border rounded hover:border-gray-900 hover:text-gray-900 transition-colors"
              >
                {size}
              </button>
            ))}
          </div> */}
        </div>
      </div>
    </div>
  );
}
