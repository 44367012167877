import React, { useState, useRef } from "react";
import { MdCloudUpload, MdDelete } from "react-icons/md";
import { useAuthState } from "react-firebase-hooks/auth";
import swal from "sweetalert";
import auth from "../../firebase.init";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import useAdmin from "../../Components/Shared/useAdmin";
import Loader from "../../Components/Common/Loader";
import imageCompression from "browser-image-compression";

export default function AddGalleryImages() {
  const imgStorageKey = "7bd193c3ab5dcf0453572e262a763279";
  const [user, loading] = useAuthState(auth);
  const [images, setImages] = useState([]);
  const [admin, adminLoading] = useAdmin(user);
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  // const handleImageUpload = async (e) => {
  //   e.preventDefault();
  //   const img = e.target.image.files[0];
  //   if (!img) {
  //     swal("Oops", "Image Must Not Be Empty", "error");
  //     return;
  //   } else {
  //     const formData = new FormData();
  //     formData.append("image", img);
  //     const url = `https://api.imgbb.com/1/upload?key=${imgStorageKey}`;

  //     fetch(url, {
  //       method: "POST",
  //       body: formData,
  //     })
  //       .then((res) => res.json())
  //       .then((result) => {
  //         if (result.success) {
  //           const image = result.data.url;

  //           if (image) {
  //             fetch("http://localhost:5000/api/gallery", {
  //               method: "POST",
  //               headers: {
  //                 authorization: `Bearer ${user?.accessToken}`,
  //                 "Content-Type": "application/json",
  //               },
  //               body: JSON.stringify({ image }),
  //             }).then((res) => {
  //               if (res.ok) {
  //                 swal("Yayy", "Image Added Successfully", "success");
  //                 setTimeout(() => {
  //                   refetch();
  //                 }, 1000);
  //                 e.target.image.value = "";
  //               } else {
  //                 swal("Error", res.message, "error");
  //               }
  //             });
  //           } else {
  //             swal(
  //               "Error",
  //               "Please check all the fields filled with and valid and ",
  //               "error"
  //             );
  //           }
  //         }
  //       });
  //   }
  // };

  // const handleImageUpload = async () => {
  //   const img = e.target.image.files[0];

  //   if (!img) {
  //     swal("Oops", "Image Must Not Be Empty", "error");
  //     return;
  //   }

  //   try {
  //     // Image compression settings
  //     const options = {
  //       maxSizeMB: 0.3, // Max file size (in MB)
  //       maxWidthOrHeight: 800, // Max width/height (in pixels)
  //       useWebWorker: true, // Faster compression
  //     };

  //     // Compress the image
  //     const compressedImage = await imageCompression(img, options);

  //     // Upload to imgbb
  //     const formData = new FormData();
  //     formData.append("image", compressedImage);
  //     const url = `http://localhost:5000/api/upload`;

  //     const res = await fetch(url, {
  //       method: "POST",
  //       body: formData,
  //     });

  //     const result = await res.json();

  //     if (result.success) {
  //       const image = result.data.url;

  //       // Save to backend if upload was successful
  //       const response = await fetch(
  //         "http://localhost:5000/api/gallery",
  //         {
  //           method: "POST",
  //           headers: {
  //             authorization: `Bearer ${user?.accessToken}`,
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({ image }),
  //         }
  //       );

  //       if (response.ok) {
  //         swal("Yayy", "Image Added Successfully", "success");
  //         setTimeout(() => {
  //           refetch();
  //         }, 1000);
  //         e.target.image.value = "";
  //       } else {
  //         swal("Error", "Failed to save image to the gallery", "error");
  //       }
  //     } else {
  //       swal("Error", "Failed to upload image", "error");
  //     }
  //   } catch (error) {
  //     console.error("Image upload error:", error);
  //     swal("Error", "Something went wrong! Try again.", "error");
  //   }
  // };
  console.log(file);
  const handleImageUpload = async () => {
    if (!file) {
      swal("Oops", "Image Must Not Be Empty", "error");
      return;
    }

    setIsLoading(true);

    try {
      // Image compression settings
      const options = {
        maxSizeMB: 0.3,
        maxWidthOrHeight: 800,
        useWebWorker: true,
      };

      // Compress the image
      const compressedImage = await imageCompression(file, options);

      // Upload to server
      const formData = new FormData();
      formData.append("image", compressedImage);
      const url = `${process.env.REACT_APP_API_URL}/upload`;

      const res = await fetch(url, {
        method: "POST",
        body: formData,
      });

      const result = await res.json();

      if (result.success) {
        const image = result.data.url;

        // Save to backend if upload was successful
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/gallery`,
          {
            method: "POST",
            headers: {
              authorization: `Bearer ${user?.accessToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ image }),
          }
        );

        if (response.ok) {
          swal("Yayy", "Image Added Successfully", "success");
          setTimeout(() => {
            refetch();
          }, 1000);
          removeFile();
        } else {
          swal("Error", "Failed to save image to the gallery", "error");
        }
      } else {
        swal("Error", "Failed to upload image", "error");
      }
    } catch (error) {
      console.error("Image upload error:", error);
      swal("Error", "Something went wrong! Try again.", "error");
    } finally {
      setIsLoading(false);
    }
  };
  const galleryQuery = useQuery({
    queryKey: ["gallery"],
    queryFn: () =>
      fetch(`${process.env.REACT_APP_API_URL}/gallery`, {
        headers: {
          authorization: `Bearer ${user?.accessToken}`,
          ContentType: "application/json",
        },
      }).then((res) => res.json()),
  });

  const gallery = galleryQuery.data;
  const refetch = galleryQuery.refetch;

  const handleDelete = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/gallery/${id}`,
      {
        method: "DELETE",
        headers: {
          authorization: `Bearer ${user?.accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    if (data) {
      swal("Yayy", "Image Deleted Successfully", "success");
      refetch();
    } else {
      swal("Error", "Something went wrong", "error");
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      handleFile(droppedFile);
    }
  };

  const handleFileInput = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      handleFile(selectedFile);
    }
  };

  const handleFile = (selectedFile) => {
    setFile(selectedFile);
    // Simulate upload progress
    setUploadProgress(0);
    const interval = setInterval(() => {
      setUploadProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 10;
      });
    }, 200);
  };

  const removeFile = () => {
    setFile(null);
    setUploadProgress(0);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <div>
      {adminLoading ? (
        <>
          <Loader />
        </>
      ) : (
        <div className="">
          {/* <div className="my-2 mt-5 p-20 bg-slate-200 w-96 m-auto">
            <form action="" onSubmit={handleImageUpload}>
              <div className="mb-3">
                <input type="file" className="form-control" id="image" />
              </div>
              <button
                type="submit"
                class="text-white w-full bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-[10px] md:text-sm px-3 py-2.5 mt-5 text-center  "
              >
                Submit
              </button>
            </form>
          </div> */}
          <div className=" bg-gray-50 flex items-center justify-center py-10 p-4">
            <div className="w-full max-w-md">
              <div className="bg-white rounded-lg shadow-lg p-6">
                <h2 className="text-2xl font-semibold text-gray-700 mb-6 text-center">
                  Upload File
                </h2>

                {/* Drag & Drop Zone */}
                <div
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                  className={`
              border-2 border-dashed rounded-lg p-8 text-center
              transition-all duration-200 ease-in-out
              ${
                isDragging
                  ? "border-emerald-500 bg-emerald-50"
                  : "border-gray-300 hover:border-emerald-400"
              }
            `}
                >
                  <input
                    type="file"
                    onChange={handleFileInput}
                    className="hidden"
                    ref={fileInputRef}
                    id="file-input"
                  />

                  <label
                    htmlFor="file-input"
                    className="cursor-pointer flex flex-col items-center gap-2"
                  >
                    <MdCloudUpload className="w-12 h-12 text-gray-400" />
                    <span className="text-gray-600">
                      {isDragging ? (
                        "Drop your file here"
                      ) : (
                        <>
                          Drag & drop your file or{" "}
                          <span className="text-emerald-500 hover:text-emerald-600">
                            browse
                          </span>
                        </>
                      )}
                    </span>
                    <span className="text-sm text-gray-500">
                      Supported files: PDF, DOC, DOCX, etc.
                    </span>
                  </label>
                </div>

                {/* File Preview */}
                {file && (
                  <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-3">
                        <div className="w-10 h-10 bg-emerald-100 rounded-lg flex items-center justify-center">
                          <MdCloudUpload className="w-6 h-6 text-emerald-600" />
                        </div>
                        <div className="flex flex-col">
                          <span className="text-sm font-medium text-gray-700">
                            {file.name}
                          </span>
                          <span className="text-xs text-gray-500">
                            {(file.size / 1024 / 1024).toFixed(2)} MB
                          </span>
                        </div>
                      </div>
                      <button
                        onClick={removeFile}
                        className="p-1 hover:bg-gray-200 rounded-full transition-colors"
                      >
                        <MdDelete className="w-5 h-5 text-gray-500" />
                      </button>
                    </div>

                    {/* Progress Bar */}
                    <div className="mt-4">
                      <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                        <div
                          className="h-full bg-emerald-500 transition-all duration-200"
                          style={{ width: `${uploadProgress}%` }}
                        />
                      </div>
                      <span className="text-xs text-gray-500 mt-1">
                        {uploadProgress}% uploaded
                      </span>
                    </div>
                  </div>
                )}

                {/* Submit Button */}
                <button
                  onClick={handleImageUpload}
                  disabled={!file || isLoading || uploadProgress < 100}
                  className={`
    w-full mt-6 py-3 px-4 rounded-lg
    text-white font-medium
    transition-all duration-200
    flex items-center justify-center gap-2
    ${
      !file || isLoading
        ? "bg-gray-300 cursor-not-allowed"
        : "bg-emerald-500 hover:bg-emerald-600"
    }
  `}
                >
                  {isLoading ? (
                    <>
                      <svg
                        className="animate-spin h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                      <span>Uploading... {uploadProgress}%</span>
                    </>
                  ) : (
                    "Upload Image"
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4 p-3">
            {gallery?.data?.map((image) => (
              <div class="w-full h-92 flex flex-col justify-between max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img
                  class=" rounded-t-lg h-full w-full object-cover object-center"
                  src={image?.image}
                  alt="product image"
                />

                <div class="px-2 md:px-3 pb-2 md:pb-3 mt-2">
                  <button
                    onClick={() => handleDelete(image?._id)}
                    class="text-white w-full bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-[10px] md:text-sm px-3 py-2.5 mt-5 text-center  "
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
