"use client";

import { useState, useRef, useEffect } from "react";
import { FaFileAlt, FaInfoCircle, FaExchangeAlt } from "react-icons/fa";

export default function ProductTabs({ product }) {
  const [activeTab, setActiveTab] = useState("description");
  const [indicatorStyle, setIndicatorStyle] = useState({ left: 0, width: 0 });
  const tabsRef = useRef([]);

  // Sample data for the tabs
  const tabs = [
    {
      id: "description",
      label: "Description",
      icon: <FaFileAlt className="h-4 w-4" />,
      content: (
        <div className="space-y-4">
          <p
            className="text-sm text-black "
            dangerouslySetInnerHTML={{
              __html: product?.data?.description,
            }}
          ></p>
        </div>
      ),
    },
    // {
    //   id: "additional",
    //   label: "Additional Information",
    //   icon: <FaInfoCircle className="h-4 w-4" />,
    //   content: (
    //     <div className="space-y-4">
    //       <p
    //         className="text-sm text-black "
    //         dangerouslySetInnerHTML={{
    //           __html: product?.data?.additionalInfo,
    //         }}
    //       ></p>
    //     </div>
    //   ),
    // },
    {
      id: "refund",
      label: "Refund Policy",
      icon: <FaExchangeAlt className="h-4 w-4" />,
      content: (
        <div className="space-y-4">
          <p
            className="text-sm text-black "
            dangerouslySetInnerHTML={{
              __html: product?.data?.refundPolicy,
            }}
          ></p>
        </div>
      ),
    },
  ];

  // Update indicator position when active tab changes
  useEffect(() => {
    const activeTabElement =
      tabsRef.current[tabs.findIndex((tab) => tab.id === activeTab)];
    if (activeTabElement) {
      setIndicatorStyle({
        left: activeTabElement.offsetLeft,
        width: activeTabElement.offsetWidth,
      });
    }
  }, [activeTab]);

  // Handle keyboard navigation
  const handleKeyDown = (e, tabId) => {
    const tabIndex = tabs.findIndex((tab) => tab.id === tabId);
    let newIndex = tabIndex;

    switch (e.key) {
      case "ArrowLeft":
        newIndex = tabIndex > 0 ? tabIndex - 1 : tabs.length - 1;
        break;
      case "ArrowRight":
        newIndex = tabIndex < tabs.length - 1 ? tabIndex + 1 : 0;
        break;
      default:
        return;
    }

    setActiveTab(tabs[newIndex].id);
    tabsRef.current[newIndex]?.focus();
  };

  return (
    <div className="max-w-7xl mx-auto">
      {/* Tab List */}
      <div className="relative border-b">
        <div className="flex overflow-x-auto hide-scrollbar">
          {tabs.map((tab, index) => (
            <button
              key={tab.id}
              ref={(el) => (tabsRef.current[index] = el)}
              onClick={() => setActiveTab(tab.id)}
              onKeyDown={(e) => handleKeyDown(e, tab.id)}
              role="tab"
              aria-selected={activeTab === tab.id}
              aria-controls={`${tab.id}-panel`}
              tabIndex={activeTab === tab.id ? 0 : -1}
              className={`
                flex items-center gap-2 px-4 py-3 min-w-[120px] text-sm font-medium
                transition-colors duration-150
                hover:text-green-500 focus:outline-none focus-visible:ring-2 
                focus-visible:ring-offset-2 focus-visible:ring-primary
                ${
                  activeTab === tab.id
                    ? "text-green-500"
                    : "text-muted-foreground"
                }
              `}
            >
              {tab.icon}
              {tab.label}
            </button>
          ))}
        </div>
        {/* Active Tab Indicator */}
        <div
          className="absolute bottom-0 h-0.5 bg-green-500 transition-all duration-200"
          style={{ left: indicatorStyle.left, width: indicatorStyle.width }}
        />
      </div>

      {/* Tab Panels */}
      <div className="mt-6">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            role="tabpanel"
            id={`${tab.id}-panel`}
            aria-labelledby={tab.id}
            hidden={activeTab !== tab.id}
            className={`
              prose max-w-none
              ${
                activeTab === tab.id ? "animate-in fade-in-50 duration-500" : ""
              }
            `}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
}
