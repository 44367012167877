import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditor = ({ value, onChange, placeholder }) => {
  const handleChange = (text) => {
    if (text !== undefined) {
      onChange(text);
    }
  };

  return (
    <div>
      <ReactQuill
        value={value || ""}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default TextEditor;
