"use client";

import { useEffect, useState } from "react";
import { MdAdd, MdDelete, MdCloudUpload, MdClose } from "react-icons/md";
import { FaYoutube } from "react-icons/fa";
import TextEditor from "../Shared/TextEditor";
import swal from "sweetalert";
import imageCompression from "browser-image-compression";
import { useParams } from "react-router-dom";
import { queryFetcher } from "../../lib/queryFetcher";
import { useQuery } from "@tanstack/react-query";
import placeholder from "../../assets/images/placeholder-image.jpg";
export default function EditModal() {
  const { id } = useParams();

  const [productData, setProductData] = useState({
    category: "",
    productName: "",
    description: "",
    price: 0,
    quantity: 0,
    discount: 0,
    status: "",
    youtubeLink: "",
    refundPolicy: "",
  });

  const [colors, setColors] = useState([]);
  // const [images, setImages] = useState(Array(4).fill(null));
  const [reviews, setReviews] = useState([]);

  const {
    data: bookingData,
    error: bookingDataError,
    isLoading: bookingDataLoading = true,
    refetch: bookingDataRefetch,
  } = useQuery({
    queryKey: ["product-by-id"],
    queryFn: () => queryFetcher("/product/", id),
    enabled: !!id,
  });

  useEffect(() => {
    if (!bookingData?.data) return;

    setProductData((prev) => ({
      ...prev, // Preserve existing state
      category: bookingData.data.category || prev.category,
      productName: bookingData.data.productName || prev.productName,
      description: bookingData.data.description || prev.description,
      refundPolicy: bookingData.data.refundPolicy || prev.refundPolicy,
      price: bookingData.data.price || prev.price,
      quantity: bookingData.data.quantity || prev.quantity,
      discount: bookingData.data.discount || prev.discount,
      status: bookingData.data.status || prev.status,
      youtubeLink: bookingData.data.youtubeLink || prev.youtubeLink,
    }));
    setColors((prevColors) => bookingData.data.colors || prevColors);
    setReviews((prevReviews) => bookingData.data.reviews || prevReviews);
    // setImages((prevImages) => bookingData.data.productImages || prevImages);
  }, [bookingData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleColorChange = (index, color) => {
    const newColors = [...colors];
    newColors[index] = { ...newColors[index], color };
    setColors(newColors);
  };

  const handleReviewChange = (index, value) => {
    const newReviews = [...reviews];
    newReviews[index] = {
      ...newReviews[index],
      review: value,
    };
    setReviews(newReviews);
  };

  const handleColorImageChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const newColors = [...colors];
      newColors[index] = {
        ...newColors[index],
        image: {
          file,
          preview: URL.createObjectURL(file),
        },
      };
      setColors(newColors);
    }
  };

  const handleReviewImageChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const newReviews = [...reviews];
      newReviews[index] = {
        ...newReviews[index],
        image: {
          file,
          preview: URL.createObjectURL(file),
        },
      };
      setReviews(newReviews);
    }
  };

  const addNewColor = () => {
    setColors([...colors, { color: "#000000", image: null }]);
  };
  const addNewReview = () => {
    setReviews([...reviews, { review: "", image: null }]);
  };
  const removeColor = (index) => {
    setColors(colors.filter((_, i) => i !== index));
  };
  const removeReview = (index) => {
    setReviews(reviews.filter((_, i) => i !== index));
  };

  // const handleImageChange = (e, index) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const newImages = [...images];
  //     newImages[index] = {
  //       file,
  //       preview: URL.createObjectURL(file),
  //     };
  //     setImages(newImages);
  //   }
  // };

  // const removeImage = (index) => {
  //   const newImages = [...images];
  //   newImages[index] = null;
  //   setImages(newImages);
  // };

  const uploadImages = async (colors = [], reviews = []) => {
    try {
      // Upload color images
      const uploadedColors = await Promise.all(
        (Array.isArray(colors) ? colors : []).map(async (colorObj) => {
          if (colorObj.image?.file) {
            try {
              const options = {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 800,
                useWebWorker: true,
              };

              const compressedImage = await imageCompression(
                colorObj.image.file,
                options
              );

              const formData = new FormData();
              formData.append("image", compressedImage);
              formData.append(
                "extension",
                colorObj.image.file.name.split(".").pop()
              );

              const res = await fetch(
                `${process.env.REACT_APP_API_URL}/upload`,
                {
                  method: "POST",
                  body: formData,
                }
              );

              const result = await res.json();
              return result.data?.url
                ? { color: colorObj.color, image: result.data.url }
                : colorObj;
            } catch (error) {
              console.error("Color image upload error:", error);
              return colorObj;
            }
          }
          return colorObj;
        })
      );

      // Upload review images
      const uploadReviews = await Promise.all(
        (Array.isArray(reviews) ? reviews : []).map(async (reviewObj) => {
          if (reviewObj.image?.file) {
            try {
              const options = {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 800,
                useWebWorker: true,
              };

              const compressedImage = await imageCompression(
                reviewObj.image.file,
                options
              );

              const formData = new FormData();
              formData.append("image", compressedImage);
              formData.append(
                "extension",
                reviewObj.image.file.name.split(".").pop()
              );

              const res = await fetch(
                `${process.env.REACT_APP_API_URL}/upload`,
                {
                  method: "POST",
                  body: formData,
                }
              );

              const result = await res.json();
              return result.data?.url
                ? { review: reviewObj.review, image: result.data.url }
                : reviewObj;
            } catch (error) {
              console.error("Review image upload error:", error);
              return reviewObj;
            }
          }
          return reviewObj;
        })
      );

      return {
        colors: uploadedColors,
        reviews: uploadReviews,
      };
    } catch (error) {
      console.error("Upload error:", error);
      throw new Error("Failed to upload images");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !productData.category ||
      !productData.productName ||
      !productData.description ||
      !productData.price ||
      !productData.quantity ||
      !productData.status
    ) {
      swal("Warning", "All fields are required.", "warning");
      return;
    }

    if (isNaN(productData.price) || Number(productData.price) < 0) {
      swal("Warning", "Price must be a valid non-negative number.", "warning");
      return;
    }

    if (isNaN(productData.quantity) || Number(productData.quantity) < 0) {
      swal(
        "Warning",
        "Quantity must be a valid non-negative number.",
        "warning"
      );
      return;
    }

    if (
      productData.discount &&
      (isNaN(productData.discount) || Number(productData.discount) < 0)
    ) {
      swal(
        "Warning",
        "Discount must be a valid non-negative number.",
        "warning"
      );
      return;
    }

    if (productData.youtubeLink == "") {
      swal("Warning", "Youtube link is required.", "warning");
      return;
    }

    // Validate images

    // Validate colors (optional but recommended)
    if (Array.isArray(colors) && colors.length === 0) {
      swal("Warning", "At least one color must be added.", "warning");
      return;
    }

    const invalidColor = colors?.find((color) => !color.image);
    if (invalidColor) {
      swal("Warning", "Each color must have an image uploaded.", "warning");
      return;
    }

    // Validate Reviews (if required)
    const invalidReview =
      reviews?.length > 0 && reviews.find((review) => !review.image);
    if (invalidReview) {
      swal("Warning", "Each review must have an image uploaded.", "warning");
      return;
    }

    try {
      // First upload and compress images
      const { colors: uploadedColors, reviews: uploadReviews } =
        await uploadImages(colors, reviews);

      // Prepare the final data
      const finalData = {
        ...productData,
        colors: uploadedColors,
        reviews: uploadReviews,
        // productImages: uploadedImages,
      };

      // Make the PUT request
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/product/${id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(finalData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update product");
      }

      const result = await response.json();
      swal("success", result.message, "success");
    } catch (error) {
      console.error("Error updating product:", error);
      // Here you might want to show an error message to the user
      swal("Error", "Failed to update product", "error");
    }
  };

  return (
    <div className="  p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-4xl w-full mx-auto ">
        <div className=" bg-white px-6 py-4 border-b ">
          <h2 className="text-xl font-semibold">Edit Product</h2>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6 p-6">
          {/* Category Select */}
          <select
            name="category"
            value={productData.category}
            onChange={handleInputChange}
            className="w-full p-2.5 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Select Category</option>
            <option value="borka">Borka</option>
            <option value="hijab">Hijab</option>
            <option value="abaya">Abaya</option>
          </select>

          {/* Product Name */}
          <input
            type="text"
            name="productName"
            value={productData.productName}
            onChange={handleInputChange}
            placeholder="Product Name"
            className="w-full p-2.5 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />

          <TextEditor
            value={productData.description || ""}
            onChange={(text) =>
              setProductData((prev) => ({ ...prev, description: text }))
            }
            placeholder="Description"
          />

          <TextEditor
            value={productData.refundPolicy || ""}
            onChange={(text) =>
              setProductData((prev) => ({ ...prev, refundPolicy: text }))
            }
            placeholder="Refund Policy"
          />

          {/* Price per unit */}
          <input
            type="number"
            name="price"
            value={productData.price}
            onChange={handleInputChange}
            placeholder="Product Price per unit"
            className="w-full p-2.5 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />

          {/* Available Quantity */}
          <input
            type="number"
            name="quantity"
            value={productData.quantity}
            onChange={handleInputChange}
            placeholder="Available Quantity"
            className="w-full p-2.5 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />

          {/* Discount */}
          <input
            type="number"
            name="discount"
            value={productData.discount}
            min={0}
            max={100}
            onChange={handleInputChange}
            placeholder="Discount"
            className="w-full p-2.5 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />

          {/* Product Status */}
          <select
            name="status"
            value={productData.status}
            onChange={handleInputChange}
            className="w-full p-2.5 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Product Status</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>

          {/* Color-wise Images */}
          <div className="space-y-4">
            {colors.map((color, index) => (
              <div
                key={index}
                className="flex items-center gap-4 p-4 border rounded"
              >
                <div className="flex items-center gap-2 flex-1">
                  <input
                    type="color"
                    value={color.color}
                    onChange={(e) => handleColorChange(index, e.target.value)}
                    className="h-10 w-16 cursor-pointer rounded border border-gray-300"
                  />
                  <span className="text-sm text-gray-600 uppercase">
                    {color.color}
                  </span>
                </div>
                <div className="relative">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleColorImageChange(e, index)}
                    className="hidden"
                    id={`color-image-${index}`}
                  />
                  <label
                    htmlFor={`color-image-${index}`}
                    className="block w-20 h-20 border-2 border-dashed border-gray-300 rounded cursor-pointer"
                  >
                    {color.image ? (
                      <img
                        src={color.image.preview || color.image}
                        alt="Color"
                        className="w-full h-full object-cover rounded"
                      />
                    ) : (
                      <div className="flex items-center justify-center h-full">
                        <MdCloudUpload className="text-2xl text-gray-400" />
                      </div>
                    )}
                  </label>
                </div>
                <button
                  type="button"
                  onClick={() => removeColor(index)}
                  className="p-2 text-red-500 hover:text-red-700"
                >
                  <MdDelete className="text-xl" />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={addNewColor}
              className="flex items-center gap-2 text-blue-500 hover:text-blue-700"
            >
              <MdAdd className="text-xl" />
              Add New Color
            </button>
          </div>
          <div className="space-y-4">
            {reviews.map((review, index) => (
              <div
                key={index}
                className="flex items-center gap-4 p-4 border rounded"
              >
                <div className="flex items-center gap-2 flex-1">
                  <textarea
                    value={review.review}
                    onChange={(e) => handleReviewChange(index, e.target.value)}
                    className="h-full w-full rounded border border-gray-300"
                  />
                  {/* <span className="text-sm text-gray-600 uppercase">
                              {review.review}
                            </span> */}
                </div>
                <div className="relative">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleReviewImageChange(e, index)}
                    className="hidden"
                    id={`review-image-${index}`}
                  />
                  <label
                    htmlFor={`review-image-${index}`}
                    className="block w-20 h-20 border-2 border-dashed border-gray-300 rounded cursor-pointer"
                  >
                    {review.image ? (
                      <img
                        src={review.image.preview || review.image}
                        alt="Color"
                        className="w-full h-full object-cover rounded"
                      />
                    ) : (
                      <div className="flex items-center justify-center h-full">
                        <MdCloudUpload className="text-2xl text-gray-400" />
                      </div>
                    )}
                  </label>
                </div>
                <button
                  type="button"
                  onClick={() => removeReview(index)}
                  className="p-2 text-red-500 hover:text-red-700"
                >
                  <MdDelete className="text-xl" />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={addNewReview}
              className="flex items-center gap-2 text-blue-500 hover:text-blue-700"
            >
              <MdAdd className="text-xl" />
              Add New Review
            </button>
          </div>
          {/* YouTube Link */}
          <div className="relative">
            <FaYoutube className="absolute left-3 top-1/2 transform -translate-y-1/2 text-red-600 text-xl" />
            <input
              type="text"
              name="youtubeLink"
              value={productData.youtubeLink}
              onChange={handleInputChange}
              placeholder="YouTube Link"
              className="w-full p-2.5 pl-10 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          {/* Multiple Images Upload */}
          {/* <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {images?.map((image, index) => (
              <div key={index} className="relative">
                <div className="border-2 border-dashed border-gray-300 rounded p-2 h-24">
                  {image ? (
                    <div className="relative w-full h-full">
                      <img
                        src={image || "/placeholder.svg"}
                        alt={`Product ${index + 1}`}
                        className="w-full h-full object-cover rounded"
                      />
                      <button
                        type="button"
                        onClick={() => removeImage(index)}
                        className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1"
                      >
                        <MdDelete className="text-sm" />
                      </button>
                    </div>
                  ) : (
                    <label className="cursor-pointer flex flex-col items-center justify-center h-full">
                      <MdCloudUpload className="text-2xl text-gray-400" />
                      <span className="text-xs text-gray-500">Upload</span>
                      <input
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={(e) => handleImageChange(e, index)}
                      />
                    </label>
                  )}
                </div>
              </div>
            ))}
          </div> */}

          {/* Action Buttons */}
          <div className="flex gap-4 justify-end">
            {/* <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Cancel
            </button> */}
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
